import React from "react"
import Helmet from "react-helmet"

const GoogleAnalytics = () => {
  return (
    <>
      <Helmet>
        <script
          type="text/plain"
          data-cookiecategory="analytics"
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-NZ12EXEGWW"
        ></script>
        <script type="text/plain" data-cookiecategory="analytics" async>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-NZ12EXEGWW'), { 'anonymize_ip': true };
          gtag('config', 'UA-216473754-1', { 'anonymize_ip': true });
          `}
        </script>

        {/* Tag Manager */}
        <script type="text/plain" data-cookiecategory="analytics" async>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MP2RMDL');          
          `}
        </script>
      </Helmet>
    </>
  )
}

export default GoogleAnalytics
