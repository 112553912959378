import { Popover, Transition } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import React, { Fragment } from "react"
import Button from "../common/Button"
import logo from "./../../media/seo-agentur-stuttgart-logo.svg"

const linkClasses =
  "lg:text-sm lg:text-base transition-colors duration-300 rounded-md hover:text-brand-yellow-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-yellow"

const linkClassesMobile =
  "bg-gray-50 text-center p-3 transition-colors duration-300 rounded-md hover:text-brand-yellow-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-yellow "

const menuItems = [
  {
    name: "3 SEO Tipps",
    href: "/#tipps",
  },
  {
    name: "About",
    href: "/#about",
  },
  {
    name: "Garantie",
    href: "/#garantie",
  },
  {
    name: "Rezensionen",
    href: "/#rezensionen",
  },
  {
    name: "FAQ",
    href: "/#faq",
  },
  {
    name: "Blog",
    href: "/blog/",
  },
  {
    name: "SEO-Flatrate",
    href: "/blog/seo-flatrate-on-offpage/",
  },
  {
    name: "Fallstudien",
    href: "/blog/fallstudien-unserer-kunden/",
  },
  {
    name: "Umsatz-Rechner",
    href: "/umsatz-rechner/",
  },
]

export default function Header() {
  return (
    <Popover className="relative bg-white">
      {({ open }) => (
        <>
          <div className="px-4 mx-auto sm:px-6">
            <div className="flex items-center justify-between py-6 lg:justify-start lg:space-x-4">
              <div className="flex justify-start lg:mr-8">
                <Link to="/">
                  <span className="sr-only">Logo</span>
                  <img
                    className="w-auto h-16 sm:h-24"
                    src={logo}
                    alt="SEO Agentur Stuttgart Logo"
                    title="SEO Agentur Stuttgart Logo"
                  />
                </Link>
              </div>
              <div className="flex items-center -my-2 -mr-2 lg:hidden">
                <Button
                  to="/anfragen/"
                  text="SEO-CHECK"
                  className="mr-3 sm:hidden"
                />
                <Button
                  to="/anfragen/"
                  text="KOSTENLOSER SEO-CHECK"
                  className="hidden mr-3 sm:flex lg:hidden"
                />

                <Popover.Button className="inline-flex items-center justify-center p-2 bg-white rounded-md hover:text-brand-yellow-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-yellow">
                  <span className="sr-only">Menü öffnen</span>
                  <MenuIcon className="w-6 h-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <nav className="hidden space-x-10 lg:flex">
                {menuItems.map(item => {
                  if (item.href.startsWith("/#")) {
                    return (
                      <AnchorLink
                        key={item.name}
                        to={item.href}
                        className={linkClasses}
                      >
                        {item.name}
                      </AnchorLink>
                    )
                  }
                  return (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={linkClasses}
                    >
                      {item.name}
                    </Link>
                  )
                })}
              </nav>
              <div className="items-center justify-end hidden lg:flex lg:flex-1 lg:w-0">
                <Button to="/anfragen/" text="KOSTENLOSER SEO-CHECK" />
              </div>
            </div>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              static
              className="absolute inset-x-0 top-0 z-50 p-2 transition origin-top-right transform lg:hidden"
            >
              <div className="relative bg-white divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50">
                <div className="flex items-center justify-between p-6">
                  <div>
                    <img
                      className="w-auto h-16"
                      src={logo}
                      alt="SEO Agentur Stuttgart Logo"
                      title="SEO Agentur Stuttgart Logo"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center p-2 bg-white rounded-md hover:text-brand-yellow-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-yellow">
                      <span className="sr-only">Menü schließen</span>
                      <XIcon className="w-6 h-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>

                <div className="px-5 py-6 space-y-6">
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    {menuItems.map(item => {
                      if (item.href.startsWith("/#")) {
                        return (
                          <AnchorLink
                            key={item.name}
                            to={item.href}
                            className={linkClassesMobile}
                          >
                            {item.name}
                          </AnchorLink>
                        )
                      }
                      return (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={linkClassesMobile}
                        >
                          {item.name}
                        </Link>
                      )
                    })}
                  </div>
                  <div>
                    <Button
                      to="/anfragen/"
                      text="KOSTENLOSER SEO-CHECK"
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
