import React, { useEffect } from "react"
import "vanilla-cookieconsent"
import pluginConfig from "./CookieConsentConfig"
import GoogleAnalytics from "./GoogleAnalytics"
import FacebookPixel from "./FacebookPixel"
import ChatBot from "./ChatBot"

const Scripts = () => {
  const isDevelopment = () => process.env.NODE_ENV === "development"
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!isDevelopment() && !document.getElementById("cc--main")) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(window as any).CookieConsentApi = window.initCookieConsent()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(window as any).CookieConsentApi.run(pluginConfig)
    }
  }, [])

  return (
    <>
      <GoogleAnalytics />
      <FacebookPixel />
      <ChatBot />
    </>
  )
}

export default Scripts
