import React from "react"
import Helmet from "react-helmet"

const GoogleAnalytics = () => {
  return (
    <>
      <Helmet>
        <script
          src="https://bemyguestmarketing.com/chatbot/chat_plugin.js"
          data-bot-id="19077"
        ></script>
      </Helmet>
    </>
  )
}

export default GoogleAnalytics
