/**
 * @type {UserConfig}
 *
 * Find Doku here: https://github.com/orestbida/cookieconsent
 */
const pluginConfig = {
  gui_options: {
    consent_modal: {
      layout: "cloud",
      position: "top center",
      transition: "slide",
    },
    settings_modal: {
      layout: "bar",
      position: "right",
      transition: "slide",
    },
  },

  current_lang: "de",
  autoclear_cookies: true,
  page_scripts: true,
  force_consent: true,
  hide_from_bots: true,
  revision: 0,

  languages: {
    de: {
      consent_modal: {
        title: "Cookie-Einstellungen",
        description:
          'Wir wollen auf unserer Webseite den bestmöglichen Service und Funktionen anbieten. Dies wird durch die Einbindung von sogenannten Cookies ermöglicht. <button type="button" data-cc="c-settings" class="cc-link">Mehr Infos</button>',
        primary_btn: {
          text: "Alle akzeptieren",
          role: "accept_all",
        },
        secondary_btn: {
          text: "Alle ablehnen",
          role: "accept_necessary",
        },
      },
      settings_modal: {
        title: "Cookie-Einstellungen",
        save_settings_btn: "Einstellungen speichern",
        accept_all_btn: "Alle akzeptieren",
        reject_all_btn: "Alle ablehnen",
        close_btn_label: "Schließen",
        cookie_table_headers: [{ col1: "Name" }, { col2: "Beschreibung" }],
        blocks: [
          {
            title: "Cookie-Nutzung 📢",
            description:
              'Wir verwenden Cookies, um die grundlegenden Funktionen der Website sicherzustellen und Ihr Online-Erlebnis zu verbessern. Du kannst für jede Kategorie wählen, ob Sie sich jederzeit an- oder abmelden möchtest. Weitere Einzelheiten zu Cookies und anderen sensiblen Daten findenst Du in der vollständigen <a href="/datenschutz/" class="cc-link">Datenschutzerklärung</a>.',
          },
          {
            title: "Unbedingt erforderliche Cookies",
            description:
              "Diese Cookies sind für das reibungslose Funktionieren der Website unerlässlich. Ohne diese Cookies würde die Website nicht richtig funktionieren",
            toggle: {
              value: "necessary",
              enabled: true,
              readonly: true,
            },
          },
          {
            title: "Leistungs- und Analyse-Cookies",
            description:
              "Diese Cookies ermöglichen es der Website, sich an die Entscheidungen zu erinnern, die Sie in der Vergangenheit getroffen haben.",
            toggle: {
              value: "analytics",
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              {
                col1: "Google Analytics",
                col2: "Sammeln von anonymisierten Besucherstatistiken zur Verbesserung von Inhalten sowie Aufbau unseres Online-Angebots. Die Daten werden für statistische und Marktforschungszwecke ausgewertet werden und zukünftige Werbemaßnahmen optimiert werden.",
              },
              {
                col1: "Google Tagmanger",
                col2: "Sammeln von anonymisierten Besucherstatistiken zur Verbesserung von Inhalten sowie Aufbau unseres Online-Angebots. Die Daten werden für statistische und Marktforschungszwecke ausgewertet werden und zukünftige Werbemaßnahmen optimiert werden.",
              },
            ],
          },
          {
            title: "Marketing-Cookies",
            description:
              "Diese Cookies ermöglichen es der Website, sich an die Entscheidungen zu erinnern, die Sie in der Vergangenheit getroffen haben und dienen dazu, personalisierte Werbung anzuzeigen.",
            toggle: {
              value: "marketing",
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              {
                col1: "Facebook Pixel",
                col2: "Der Facebook Pixel wird eingesetzt, um anonymisierte Besucherdaten zu erfassen, die zur Verfeinerung und Optimierung unserer Webinhalte sowie zur Gestaltung unseres Online-Auftritts beitragen. Diese Daten werden herangezogen, um umfassende statistische Analysen durchzuführen und tiefergehende Marktforschung zu betreiben. Basierend auf diesen Erkenntnissen werden zukünftige Werbeaktionen gezielt angepasst und verbessert, um die Effektivität unserer Online-Marketingstrategien zu steigern und ein maßgeschneidertes Erlebnis für die Nutzer zu schaffen.",
              },
            ],
          },
          {
            title: "Mehr Informationen",
            description:
              'Bei Fragen zu unserer Richtlinie zu Cookies und Ihren Auswahlmöglichkeiten <a class="cc-link" href="/impressum/">kontaktiere uns bitte</a>.',
          },
        ],
      },
    },
  },
}

export default pluginConfig
