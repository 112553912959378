import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Footer from "./Footer"
import Header from "./Header"
import AppleWebAppMeta from "./meta/AppleWebAppMeta"
import Facebook from "./meta/Facebook"
import Favicons from "./meta/Favicons"
import Twitter from "./meta/Twitter"
import Scripts from "./scripts/Scripts"

const Default = ({
  children,
  location,
  title = "Standard Title",
  desc = "Standard Desc",
  imageFacebook,
  imageFacebookWidth,
  imageFacebookHeight,
  imageTwitter,
  noHeader,
  grayFooter,
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author {
              name
              summary
            }
          }
        }
      }
    `
  )

  const [isSSR, setIsSSR] = useState(true)

  useEffect(() => {
    setIsSSR(false)

    // set class for cookie banner
    if (!document.body.classList.contains("theme_cookies")) {
      document.body.classList.add("theme_cookies")
    }
  }, [])

  return (
    <>
      <Helmet>
        <html lang="de" />
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta name="robots" content="index,follow" />
        <link rel="icon" href="/favicon.ico" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={data.site.siteMetadata.title} />
        <link
          rel="canonical"
          href={`${data.site.siteMetadata.siteUrl}${location.pathname}`}
        />
        <meta
          name="author"
          content={`${data.site.siteMetadata.author.name} - ${data.site.siteMetadata.author.summary}`}
        />
      </Helmet>

      {/* Favicon generte hier --> https://www.favicon-generator.org/ */}
      <Favicons />

      {/* AppleWebAppMeta */}
      <AppleWebAppMeta />

      {/* Facebook */}
      <Facebook
        url={data.site.siteMetadata.siteUrl + location.pathname}
        title={title}
        desc={desc && desc}
        siteName={title}
        locale="de_DE"
        imageFacebook={imageFacebook && imageFacebook}
        imageFacebookWidth={imageFacebookWidth && imageFacebookWidth}
        imageFacebookHeight={imageFacebookHeight && imageFacebookHeight}
      />

      {/* Twitter */}
      <Twitter
        url={data.site.siteMetadata.siteUrl + location.pathname}
        title={title}
        imageTwitter={imageTwitter && imageTwitter}
        desc={desc && desc}
      />

      {/* Scripts */}
      {!isSSR ? <Scripts /> : null}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-MP2RMDL"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="tag-manager"
        ></iframe>
      </noscript>

      {noHeader ? "" : <Header />}
      {children}
      <Footer grayFooter={grayFooter ? true : false} />
    </>
  )
}

export default Default
